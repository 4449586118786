import React from 'react';
import styled from 'styled-components';
import Gallery from 'react-grid-gallery';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  {
    datoCmsGalerij {
      galerij {
        url
        width
        height
      }
    }
  }
`;

const Wrapper = styled.section`
  margin: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin: 3rem 28rem 5rem 5rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    margin: 3rem 28rem 5rem 15rem;
  }
  ::after {
    content: '';
    display: block;
    clear: both;
  }
`;

const Galerij = () => {
  const data = useStaticQuery(query);
  const { galerij } = data.datoCmsGalerij;
  const gallery = galerij.map(image => ({
    src: image.url,
    thumbnail: image.url,
    thumbnailWidth: image.width,
    thumbnailHeight: image.height,
  }));
  return (
    <Wrapper>
      <Gallery
        images={gallery}
        enableImageSelection={false}
        backdropClosesModal
      />
    </Wrapper>
  );
};

export default Galerij;
